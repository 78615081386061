import config from '../config';

export function startEruda(callback = () => {}) {
	// мобильная консоль еруда
	if (process.env.NODE_ENV === 'development' && config.mobileConsole) {
		let script = document.createElement("SCRIPT"),
			head = document.getElementsByTagName( "head" )[ 0 ];

		script.type = "text/javascript";
		script.src = `https://${config.mibileConsoleCDN}`;

		head.appendChild( script );
		script.onload = function () { 
			window.eruda.init()
			callback(true)
		}
	}
}