import React from 'react';
import { Alert } from '@vkontakte/vkui';
import app from './app';

export function showAnswerMessage(title, message, callbackYes = () => {}, callbackNo = () => {}) {
	showAlert(
		<Alert
			actions={[{
				title: 'Отмена',
				autoclose: true,
				style: 'cancel',
				action: () => callbackNo(true),
			},{
				title: 'Хорошо',
				autoclose: true,
				style: 'destructive',
				action: () => callbackYes(true),
			}]}
			onClose={() => { closeAlert() }}
		>
			<h2>{title}</h2>
			<p>{message}</p>
		</Alert>
	)
}

export function showInfoMessage(title, message, callback) {
	showAlert(
		<Alert
			actions={[{
				title: 'Хорошо',
				autoclose: true,
				style: 'cancel'
			}]}
			onClose={() => { closeAlert(callback) }}
		>
			<h2>{title}</h2>
			<p>{message}</p>
		</Alert>
	)
}

export function showErrorMessage(title, message, callback) {
	showAlert(
		<Alert
			actions={[{
				title: 'Хорошо',
				autoclose: true,
				style: 'cancel'
			}]}
			onClose={() => { closeAlert(callback) }}
		>
			<h2>{title}</h2>
			<p>{message}</p>
		</Alert>
	)
}

function showAlert(alert) {
	app.setState({
		popout: alert
	})
}

function closeAlert(callback = () => {}) {
	app.setState({
		popout: null
	}, callback)
}